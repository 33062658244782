<template>

<!-- //上传活动直播回放 -->
  <div class="content">
    <dc-upload
      :class="up_class"
      ref="upload"
      :dcAutoUpload="true"
      :show-file-list="showList"
      :dcdom="dcdom"
      :dcConfig="dcConfig"
      :accept="accept"
      :limit="limit"
      :on-remove="onRemove"
      @dc-success="dcSuccess"
      @dc-progress="dcProgressTrue = false"
      v-if="isOk"
    >
      <slot></slot>
    </dc-upload>
  </div>
</template>

<script>
export default {
  props: {
    showList: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 3,
    },
    up_class: {
      type: String,
      default: "my_uploader",
    },
  },
  data() {
    return {
      //金山云上传配置
      dcConfig: {
        key: "", // 后台域名对应key
        notify_url: "", // 上传接口地址
      },
      isOk: false,
      dcdom: Math.random().toString(),
    };
  },
  methods: {
    // 上传成功后的回调
    async dcSuccess(data) {
      console.log(data, "地址");
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      resData.ETag = resData.ETag.replace(/"/g, "");
      this.isUpOk = false;
      //erg
      let res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: resData.ETag,
        },
      });
      this.$emit("onGetId", res.data.data);
    },
    // 删除文件
    onRemove(file, fileList) {
      console.log(file, "file");
      console.log(fileList, "fileList");
      this.$emit("onRemoveFile", file);
    },


    //初始化金山云
    async _getAddress() {
      let res = await this.$axios.get("/k3sSetting");
      this.dcConfig.key = res.data.data.key;
      this.dcConfig.notify_url = res.data.data.callBack;
      this.isOk = true;
    },
  },
  created() {
    // 获取金山云配置
    this._getAddress();
  },
};
</script>
 
<style lang="less" scoped>
.content {
  /deep/ .el-upload {
    position: relative;
    /* 垂直水平居中 */
    .el-progress {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%,-50%);
    }
  }
}
</style>